import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class LoginForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            login: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        onSuccess: null,
    };
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        inputStyle: 'transparentV2',
        formState: {
            email: null,
            password: null,
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { actions } = this.props;

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        return actions.login({
            email: formState.email,
            password: formState.password,
        })
            .then(response => {
                this.setState({ isPending: false });
                this.onSuccess(response);
            })
            .catch(error => {
                Logger.error('[LOGIN_FORM] Error', error);

                this.setState({
                    isPending: false,
                    errors: error?.payload?.validationErrors,
                    globalError: error?.payload?.message,
                });
            });
    }

    onSuccess = response => {
        const { onSuccess } = this.props;

        if (onSuccess) {
            return onSuccess(response);
        }

        return null;
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, globalError, errors, isPending, inputStyle } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-login-form"
            >
                <Form
                    data={formState}
                    onStateChange={this.onStateChange}
                    errors={errors}
                    globalError={globalError}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                >
                    <Input
                        label="E-mail"
                        name="email"
                        placeholder="Wprowadź adres email"
                        type="email"
                        style={inputStyle}
                        styleVersion={2}
                        border='light'
                    />
                    <Input
                        label="Hasło"
                        name="password"
                        placeholder="Wprowadź Twoje hasło"
                        type="password"
                        style={inputStyle}
                        styleVersion={2}
                        border='light'
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="large"
                        style="newPage"
                    >
                        Zaloguj się
                    </Button>
                </Form>
            </StyledComponent>
        );
    }
}
