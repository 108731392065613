import { connect } from 'react-redux';

import { login } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({}), 
    dispatch => ({
        actions: {
            login: dispatch(login),
        },
    })
)(Component);

