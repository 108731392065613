import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    .submit-button {
        margin: .5em 0;
    }

    @media (max-width: ${variables.mobileL}) {
        .form-element {
            width: 100% !important;
        }
    }
`;
