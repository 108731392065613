import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default () =>
    css({
        backgroundColor: variables.dpc_backgroundWhite,
        margin: '0 auto',
        padding: '5em 0',
        width: '100%',

        '.layout-container': {
            maxWidth: '50em',
            minHeight: '93vh',
            position: 'relative',
        },

        '.section-container': {
            width: '100%',
            maxWidth: '30em',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            '.logo': {
                width: '80%',
                margin: '0 auto 2em auto',
            },

            '.section-title': {
                textAlign: 'center',
                fontSize: '2.5em',
                color: variables.dpc_fontDark,
                marginBottom: '1em',
            },
            '.section-disclaimer': {
                color: variables.dpc_fontDark,
                fontSize: '.9em',
                textAlign: 'center',
                marginTop: '1em',
                fontWeight: '400', 

                a: {
                    color: variables.dpc_fontDark,
                    fontWeight: '300',
                    fontSize: '1.1em',
                },
            },
        },
    });
