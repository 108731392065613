import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { PUBLIC_USER_PASSWORD_RESET, PUBLIC_USER_LOGIN, PUBLIC_HOME } from 'Consts/routes';

import { getRolePath } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import LoginForm from 'Components/publicUser/LoginForm';
import Spinner from 'Components/layout/Spinner';

export default class PublicLogin extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        authToken: PropTypes.string,
        profile: PropTypes.shape({
            role: PropTypes.string.isRequired,
        }),
    };

    componentDidMount = () => {
        this.redirectToRole();
    };

    componentDidUpdate = () => {
        this.redirectToRole();
    }

    redirectToRole = () => {
        const { authToken, profile, history } = this.props;

        if (authToken && profile) {
            const rolePath = getRolePath(profile);
            if(rolePath?.path) {
                history.push(rolePath.path);
            }
        }
    }

    onSuccess = response => {
        const { history, location } = this.props;
        const profile = response?.payload?.me;

        if (profile && location.pathname === PUBLIC_HOME.path || location.pathname === PUBLIC_USER_LOGIN.path ) {
            const rolePath = getRolePath(profile);
            if(rolePath?.path) {
                history.push(rolePath.path);
            }
        }
    }

    render() {
        const { authToken, profile } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-login-page"
            >
                <LayoutContainer>
                    {authToken && !profile && (<Spinner />)}
                    <div className="section-container">
                        <div className="logo">
                            <img src={require('Theme/images/logo-color-black.png')} />
                        </div>
                        <h2 className="section-title">Zaloguj się!</h2>
                        <LoginForm onSuccess={this.onSuccess} />
                        <p className="section-disclaimer">
                            Jesteś nowym użytkownikiem i chcesz stworzyć hasło <br/>
                            lub jesteś klientem i nie pamiętasz hasła?<br/>
                            <NavLink className="link" to={PUBLIC_USER_PASSWORD_RESET.path}>
                                Kliknij tutaj
                            </NavLink>
                        </p>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
